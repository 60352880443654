#root,
html,
body {
  height: 100%;
  /* font-family: "Cerebri Sans,sans-serif"; */
  font-family: "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

a {
  color : "#586b81"
}

.header {
  width: 100%;
  bottom: 0;
  top: 0;
  text-align: right;
  z-index: 9;
  position: fixed;
}

.logo {
  /* text-transform: uppercase; */
  padding-top: 20px;
  padding-bottom: 20px;
  letter-spacing: 2px;
  line-height: 40px;
  text-align: center;
}

.logo h1{
  font-size: 40px;
}

.centerthings {
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 2px;
  line-height: 40px;
  text-align: center;
}

.bg {
  background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }

.table {
  display: table;
  width: 100%;
  height: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.projects {
  padding-left: 80px;
  padding-right: 50px;
}

.project-item {
  position: relative;
  margin-bottom: 40px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}


.ToggleSwitch {
  position: relative;
  width: 5rem;
  height: 2.35rem;
  display: inline-block;
}
.ToggleSwitch.ToggleSwitch__rounded .Slider {
  border-radius: 15rem;
  background: #eceff1;
  border: 1px solid #A0A0A0;
}
.ToggleSwitch.ToggleSwitch__rounded .Slider:before {
  border-radius: 50%;
}
.ToggleSwitch .ToggleSwitch__wrapper {
  position: relative;
  width: 5rem;
  height: 2.35rem;
}
.ToggleSwitch .ToggleSwitch__wrapper .Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .4s ease;
}
.ToggleSwitch .ToggleSwitch__wrapper .Slider:before {
  width: 2.45rem;
  height: 2.45rem;
  position: absolute;
  background: #fff;
  content: '';
  margin: 0;
  padding: 0;
  top: 50%;
  left: 0.10rem;
  transform: translateY(-50%);
  transition: .4s;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked {
  background: #fff;
}
.ToggleSwitch .ToggleSwitch__wrapper .Slider.isChecked:before {
  left: calc(100% - 0.10rem - 2.45rem);
  background: #0099ff;
}


.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.card.features {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 5px 7px 0 rgba(0,0,0,.04);
  transition: all .3s ease;
  margin-bottom: 2rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  height:200px
}

.card.features:before {
  content: "";
  position: absolute;
  width: 3px;
  color: #121177;
  background: -moz-linear-gradient(top,#586b81 0%,#2d323e 100%);
  background: -webkit-linear-gradient(top,#586b81 0%,#2d323e 100%);
  background: linear-gradient(to bottom,#586b81 0%,#2d323e 100%);
  top: 0;
  bottom: 0;
  left: 0;
}

.features {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 5px 7px 0 rgba(0,0,0,.04);
  transition: all .3s ease;
}

.mediea {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.card-title {
  margin-bottom: .75rem;
}

.card-text {
  font-size: 14px;
}

.darkestblue {
  color: #2d323e;
}

.darkblue {
  color: #3e4c5b;
}

.lightblue {
  color : #586b81;
}

/* .bg-ellipses {
  background-color: transparent!important;
  background-repeat: no-repeat;
  background-image: radial-gradient(#ffffff,rgba(149, 115, 177, 0.87) 70%,transparent 70.1%);
  background-size: 200% 150%;
  background-position: bottom;
} */

/* .custom-bg-ellipses {
  background: rgb(45,50,62);
background: linear-gradient(90deg, rgba(45,50,62,1) 0%, rgba(62,76,91,1) 35%, rgba(255,255,255,1) 100%);
} */